import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { buildUrl } from '@wizbii-utils/angular/core';
import { JwtSet, JwtState } from '@wizbii-utils/angular/stores';
import { AuthenticationWebservice } from '@wizbii-utils/angular/webservices';
import { of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

export const loggedGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authenticationWebservice = inject(AuthenticationWebservice);
  const store = inject(Store);

  const loginToken = route.queryParamMap.get('login-token');

  if (loginToken) {
    return authenticationWebservice.getJwtFromLoginToken(loginToken).pipe(
      switchMap((jwtToken) => store.dispatch(new JwtSet(jwtToken))),
      catchError(() => {
        ssoRedirect(state.url);
        return of(false);
      })
    );
  }

  return store.select(JwtState.isLogged).pipe(
    take(1),
    map((logged) => {
      if (!logged) {
        ssoRedirect(state.url);
      }
      return logged;
    })
  );
};

const ssoRedirect = (url: string): void => {
  window.open(
    buildUrl(environment.api.sso, {
      redirect_url: `https://${environment.appUrl}${url}`,
      appId: environment.applicationId,
    }),
    '_self'
  );
};
